import * as React from "react"
import { Link } from "gatsby"

export default function SiteBranding({ className, siteName }) {
    return (
        <div className={className}>
            <Link
                className="inline-flex items-center px-12 py-2 h-12 text-lg font-bold text-white leading-none tracking-widest uppercase"
                to="/"
                title={siteName}
            >
                {siteName}
            </Link>
        </div>
    )
}
