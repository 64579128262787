import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SiteBranding from "./siteBranding"
import CustomLink from "./customLink"

import CloseIcon from "./icons/close"
import MenuIcon from "./icons/menu"

const Header = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            primary: prismicGeneralSettingsBodyPrimaryNav {
                items {
                    menu_link {
                        uid
                        link_type
                        type
                    }
                    menu_name {
                        text
                    }
                }
            }
            secondary: prismicGeneralSettingsBodySecondaryNav {
                items {
                    menu_link {
                        uid
                        link_type
                        type
                    }
                    menu_name {
                        text
                    }
                }
            }
        }
    `)

    const [show, setShow] = React.useState(false)

    return (
        <header
            className={`z-20 relative bg-primary ${
                show ? "max-md:fixed max-md:inset-0" : ""
            }`}
        >
            <div
                className={`mx-auto px-6 ${
                    show ? "" : "h-12"
                } max-w-6xl flex flex-col md:flex-row md:items-center`}
            >
                <button
                    className={`md:hidden absolute right-6 ${
                        show ? "top-2" : "top-0 bottom-0 my-auto"
                    } text-white rounded-full appearance-none focus:outline-none md:opacity-75 md:hover:opacity-100 transition`}
                    title="Menu"
                    onClick={() => setShow(!show)}
                >
                    {show ? <CloseIcon width={28} /> : <MenuIcon width={28} />}
                </button>

                <nav
                    className={`${
                        show ? "" : "max-md:hidden"
                    } md:flex md:flex-1 md:justify-end`}
                >
                    <ul className="md:flex md:items-center text-lg md:text-sm font-medium text-white max-md:text-center">
                        {data.primary.items.map((item, i) => (
                            <li
                                className="max-md:mb-3 md:mr-3 lg:mr-8 md:last:mr-0"
                                key={i}
                            >
                                <CustomLink
                                    className="inline-block md:px-3 md:py-2 md:rounded-md md:hover:bg-white md:hover:bg-opacity-20 max-md:opacity-75 max-md:hover:opacity-100 transition"
                                    element={item.menu_link}
                                    content={item.menu_name?.text}
                                />
                            </li>
                        ))}
                    </ul>
                </nav>

                <div className="order-first md:order-none flex flex-1 items-center justify-center">
                    <SiteBranding siteName={siteTitle} />
                </div>

                <nav
                    className={`${
                        show ? "" : "max-md:hidden"
                    } md:flex md:flex-1 md:justify-start`}
                >
                    <ul className="md:flex md:items-center text-lg md:text-sm font-medium text-white max-md:text-center">
                        {data.secondary.items.map((item, i) => (
                            <li
                                className="max-md:mb-3 md:mr-3 lg:mr-8 md:last:mr-0"
                                key={i}
                            >
                                <CustomLink
                                    className="inline-block md:px-3 md:py-2 md:rounded-md md:hover:bg-white md:hover:bg-opacity-20 max-md:opacity-75 max-md:hover:opacity-100 transition"
                                    element={item.menu_link}
                                    content={item.menu_name?.text}
                                />
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
