import * as React from "react"

export default function CaretDown({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <rect width="256" height="256" fill="none"></rect>
            <polyline
                points="208 96 128 176 48 96"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="20"
            ></polyline>
        </svg>
    )
}
