import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import PrismicLink from "./prismicLink"
import CustomLink from "./customLink"

const Footer = ({ spacedFooter, siteTitle }) => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            footer: prismicGeneralSettingsBodyFooterArea {
                primary {
                    like {
                        raw
                    }
                    like_image {
                        alt
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    like_link {
                        uid
                        link_type
                        type
                        url
                        target
                    }
                    content {
                        raw
                    }
                }
            }
            nav: prismicGeneralSettingsBodyFooterNav {
                items {
                    menu_link {
                        uid
                        link_type
                        type
                        url
                        target
                    }
                    menu_name {
                        text
                    }
                }
            }
        }
    `)

    return (
        <footer
            className={`bg-gray-100 ${spacedFooter ? "mb-14" : ""}`}
            id="footer"
        >
            <div className="mx-auto px-6 py-10 max-w-7xl">
                <div className="grid md:grid-cols-2 gap-12 mb-10 pb-10 border-b border-gray-200">
                    <div className="prose md:prose-lg">
                        <RichText
                            render={data.footer.primary?.like?.raw}
                            serializeHyperlink={PrismicLink}
                        />
                    </div>
                    <div className="flex justify-center">
                        <a
                            href={data.footer.primary?.like_link?.url}
                            target={data.footer.primary?.like_link?.target}
                            rel={
                                data.footer.primary?.like_link?.target
                                    ? "noopener noreferrer"
                                    : ""
                            }
                            title={
                                data.footer.primary?.like_image?.alt ||
                                siteTitle
                            }
                        >
                            <GatsbyImage
                                width={340}
                                height={130}
                                image={getImage(
                                    data.footer.primary?.like_image?.localFile
                                )}
                                alt={
                                    data.footer.primary?.like_image?.alt ||
                                    siteTitle
                                }
                                quality={100}
                            />
                        </a>
                    </div>
                </div>

                <div className="mb-10 prose prose-sm text-gray-700 max-w-none">
                    <RichText
                        render={data.footer.primary?.content?.raw}
                        serializeHyperlink={PrismicLink}
                    />
                </div>

                <div className="sm:flex">
                    <p className="mb-2 sm:mb-0 md:mr-2 text-gray-600">
                        © {new Date().getFullYear()} {siteTitle}.
                    </p>
                    <ul className="sm:flex sm:items-center text-gray-600 sm:space-x-6">
                        {data.nav.items.map((item, i) => (
                            <li key={i}>
                                {item.menu_link.link_type === "Document" && (
                                    <CustomLink
                                        className="hover:text-gray-700 border-b border-gray-300"
                                        activeClassName="text-gray-700"
                                        element={item.menu_link}
                                        content={item.menu_name?.text}
                                    />
                                )}
                            </li>
                        ))}
                        <li className="sm:flex sm:items-center mt-2 sm:mt-0">
                            <p className="md:mr-2 text-gray-600">
                                Ver la TV en directo en otros idiomas :{" "}
                            </p>
                            <ul className="flex items-center text-gray-600 space-x-3">
                                <li>
                                    <a
                                        className="hover:text-gray-700 border-b border-gray-300"
                                        href="https://www.ledirect.fr"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        France
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="hover:text-gray-700 border-b border-gray-300"
                                        href="https://www.tv-directo.es"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        España
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="hover:text-gray-700 border-b border-gray-300"
                                        href="https://www.tv-in-diretta.it"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        Italia
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer
