import * as React from "react"
import { RichText } from "prismic-reactjs"
import CaretDownIcon from "./icons/caretDown"

export default function Head({ data }) {
    return (
        <div
            className="py-10 bg-blue-700"
            style={{
                background:
                    "linear-gradient(90deg, rgba(65,137,188,1) 0%, rgba(77,161,220,1) 100%)",
            }}
        >
            <div className="mx-auto px-6 max-w-6xl text-center">
                <h1 className="mb-6 text-3xl sm:text-4xl md:text-5xl text-white">
                    {RichText.asText(data.page_title.raw)}
                </h1>
                <div className="mb-8 text-lg sm:text-xl font-light text-white">
                    <RichText render={data.page_description.raw} />
                </div>
                <a
                    className="inline-block mx-auto text-white transform hover:opacity-75 hover:translate-y-1"
                    href="#content"
                    title="Scroll"
                >
                    <CaretDownIcon width="40" />
                </a>
            </div>
        </div>
    )
}
