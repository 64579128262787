import * as React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../utils/linkResolver"

const PrismicLink = (type, element, content, children, index) => {
    if (element.data.link_type === "Document") {
        return (
            <Link to={linkResolver(element.data)} key={element.data.id}>
                {content}
            </Link>
        )
    }

    if (element.data.link_type === "Web") {
        const relAttr = element.data.target ? "noopener" : ""

        return (
            <a
                href={element.data.url}
                target={element.data.target}
                rel={relAttr}
            >
                {content}
            </a>
        )
    }

    return null
}

export default PrismicLink
