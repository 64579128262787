import * as React from "react"

export default function Menu({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="20"
                d="M40 128h176M40 64h176M40 192h176"
            />
        </svg>
    )
}
