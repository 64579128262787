import * as React from "react"

export default function Close({ className, width }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 24}
            viewBox="0 0 256 256"
        >
            <path fill="none" d="M0 0h256v256H0z" />
            <path fill="currentColor" d="M128 24a104 104 0 10104 104A104.12 104.12 0 00128 24zm37.656 130.344a8 8 0 11-11.312 11.312L128 139.312l-26.344 26.344a8 8 0 01-11.312-11.312L116.688 128l-26.344-26.344a8 8 0 0111.312-11.312L128 116.688l26.344-26.344a8 8 0 0111.312 11.312L139.312 128z" />
        </svg>
    )
}
